import React, { useState, useRef } from "react"
import Layout from "../components/Layout"
import SEO from "../components/seo"
import { Link, graphql } from "gatsby"
import { Grid, Breadcrumbs, Button } from "@material-ui/core"
// import Img from "gatsby-image"
import { query } from "../helper/image"
import HomeOutlinedIcon from "@material-ui/icons/HomeOutlined"
import { navigate } from "gatsby"
import ReactDOM from "react-dom"
import ArrangementCard from "../components/ArrangementCard"
import Grow from '@material-ui/core/Grow';

const Rooms = props => {
  const { totalCount, nodes } = props.data.allFile
  const {
    siteMetadata: { title },
  } = props.data.site

  const [checked, setChecked] = useState(false);


  return (
    <Layout cat="rooms">

      <SEO title={title} />


      <h1>Außergewöhnliche Locations</h1>
      {/* <div className={styles.wrapper}>xxxxx</div> */}
      <Grid container spacing={3}>
        {nodes.map((node, index) => {
            const { frontmatter, fields } = node.childMarkdownRemark
            const { headerImageAlias } = frontmatter
            const { slug } = fields
            const imageSrc = headerImageAlias ? props.data[headerImageAlias].childImageSharp.fluid.src : "";

            const animateAndLink = () => {
                // #1 style card
                // cardEl.current.style.background = "#f00"
                // const newEl = React.cloneElement(cardEl.current, {});
                // setGhostCard(newEl);

                // #2 navgate to detail seite (still needed?)
                navigate(`/rooms${slug}`)
            }

          return (
            <Grid key={index} item xs={12} md={6} lg={6} xl={6}>
              <ArrangementCard
                node={node}
                imageSrc={imageSrc}
                // href={`/rooms${slug}`}
                onClick={animateAndLink}
                isVisible={true}
              />
            </Grid>
          )
        })}
      </Grid>
      <br />
      <div>Insgesamt {totalCount} außergewöhnliche Locations</div>
      <br />
      <Link to="/">Zur Übersicht</Link>
    </Layout>
  )
}

export const getData = graphql`
  {
    site {
      siteMetadata {
        title
        description
      }
    }
    allFile(
      filter: { sourceInstanceName: { eq: "rooms" }, extension: { eq: "md" } }
      sort: { fields: childMarkdownRemark___frontmatter___order, order: ASC }
    ) {
      totalCount
      nodes {
        relativeDirectory
        childMarkdownRemark {
          frontmatter {
            name
            room
            description
            shortDescription
            headerImageAlias
          }
          fields {
            slug
          }
        }
      }
    }
    schoenbuch: file(
      relativePath: {
        eq: "hotel-schoenbuch/Hotel_Schoenbuch_12_07_2018_357_2000px.jpg"
      }
    ) {
      childImageSharp {
        fluid {
          src
        }
      }
    }
    karlstalAlteReithalle: file(
      relativePath: {
        eq: "karlstal-alte-reithalle/reithalle.jpg"
      }
    ) {
      childImageSharp {
        fluid {
          src
        }
      }
    }
    mustermann: file(relativePath: { eq: "hotel-mustermann/muster2.jpg" }) {
      childImageSharp {
        fluid {
          src
        }
      }
    }
    waldenbuch: file(relativePath: { eq: "krone-waldenbuch/Festsaal.jpg" }) {
      childImageSharp {
        fluid {
          src
        }
      }
    }
    cookroom: file(relativePath: { eq: "cookroom/room.jpg" }) {
      childImageSharp {
        fluid {
          src
        }
      }
    }
    festscheune_achalm_hof: file(relativePath: { eq: "festscheune-achalm-hof/Festscheune_Hussenstuehle.jpg" }) {
      childImageSharp {
        fluid {
          src
        }
      }
    }
  }
`

export default Rooms

// {
//   allFile(filter: {sourceInstanceName: {eq: "markdown"}}) {
//     totalCount
//     nodes {
//       relativeDirectory
//       childMarkdownRemark {
//         frontmatter {
//           name
//           room
//         }
//       }
//     }
//   }
// }

// {
//   "data": {
//     "allFile": {
//       "totalCount": 7,
//       "nodes": [
//         {
//           "relativeDirectory": "categories",
//           "childMarkdownRemark": {
//             "frontmatter": {
//               "name": "XXX GANS<br>nbuch",
//               "room": "Lernraum Kaleidoskop"
//             }
//           }
//         },
//         {
//           "relativeDirectory": "rooms/hotel-schoenbuch",
//           "childMarkdownRemark": {
//             "frontmatter": {
//               "name": "Hotel Schönbuch",
//               "room": "Lernraum Kaleidoskop"
//             }
//           }
//         },
//         {
//           "relativeDirectory": "rooms/hotel-zum-schwan",
//           "childMarkdownRemark": {
//             "frontmatter": {
//               "name": "Hotel Zum Schwan",
//               "room": "Ihr Raum"
//             }
//           }
//         },
//         {
//           "relativeDirectory": "rooms/hotel-mustermann",
//           "childMarkdownRemark": {
//             "frontmatter": {
//               "name": "Hotel Mustermann",
//               "room": null
//             }
//           }
//         },
//         {
//           "relativeDirectory": "rooms",
//           "childMarkdownRemark": {
//             "frontmatter": {
//               "name": "Hotel Mustermann",
//               "room": null
//             }
//           }
//         },
//         {
//           "relativeDirectory": "rooms/hotel-schoenbuch",
//           "childMarkdownRemark": null
//         },
//         {
//           "relativeDirectory": "rooms/hotel-schoenbuch",
//           "childMarkdownRemark": null
//         }
//       ]
//     }
//   }
// }

// {
//   allFile(filter: {sourceInstanceName: "rooms", extension: {eq: "md"}}) {
//     totalCount
//     nodes {
//       relativeDirectory
//       childMarkdownRemark {
//         frontmatter {
//           name
//           room
//         }
//       }
//       name
//     }
//   }
// }
