import React from "react"
import Card from "@material-ui/core/Card"
import CardActionArea from "@material-ui/core/CardActionArea"
import CardActions from "@material-ui/core/CardActions"
import CardContent from "@material-ui/core/CardContent"
import CardMedia from "@material-ui/core/CardMedia"
import Button from "@material-ui/core/Button"
import Typography from "@material-ui/core/Typography"
import styles from './arrangementCard.module.scss'
import Grow from '@material-ui/core/Grow';

const ArrangementCard = ({ node, imageSrc, onClick, isVisible }) => {
  const { frontmatter } = node.childMarkdownRemark
  const { name, room, shortDescription } = frontmatter

  return (
    <Grow in={isVisible}
      // {...(isVisible ? { timeout: 1000 } : {})}
    >
    <Card>
      <CardActionArea
        // href={`/rooms${slug}`}
        onClick={onClick}
      >
        <CardMedia
          // className={classes.media}
          image={imageSrc}
          style={{ height: 200, margin: 10, borderRadius: 4 }}
          title={name}
        />
        <CardContent>
          <Typography
            variant="subtitle1"
            color="textSecondary"
            component="p"
            style={{
              textAlign: "center",
              textTransform: "uppercase",
              fontSize: 14,
              marginBottom: 10,
            }}
          >
            {name}
          </Typography>
          <h2
            // variant="h5"
            // component="h2"
            className={styles.headline}
          >
            {room}
          </h2>
          <div
            variant="body2"
            component="p"
            className={styles.copy}
          >
            {shortDescription && shortDescription}
          </div>
        </CardContent>
      </CardActionArea>
      {/* <CardActions>
                  <Link to={`rooms/${slug}`}>
                    {room} ({name})
                  </Link>
                  <Button size="small" color="primary">
                    Share
                  </Button>
                  <Button size="small" color="primary">
                    Learn More
                  </Button>
                </CardActions> */}
    </Card>
    </Grow>
  )
}

export default ArrangementCard
